"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLOR_BACKGROUND = exports.COLOR_DATA_NODE = exports.GENERATE_NODE_AT_ONCE = exports.RESET_NUM = exports.DATA_NODE_RADIUS = exports.TIME_GENERATE_MOVING_DOTS = exports.MAX_VELOCITY = exports.MIN_VELOCITY = exports.MAX_RADIUS = exports.MIN_RADIUS = exports.MAX_NUM_DOTS = exports.MIN_NUM_DOTS = void 0;
exports.MIN_NUM_DOTS = 20;
exports.MAX_NUM_DOTS = 30;
exports.MIN_RADIUS = 10;
exports.MAX_RADIUS = 15;
exports.MIN_VELOCITY = 10;
exports.MAX_VELOCITY = 500;
exports.TIME_GENERATE_MOVING_DOTS = 500;
exports.DATA_NODE_RADIUS = 15;
exports.RESET_NUM = 90;
exports.GENERATE_NODE_AT_ONCE = 3;
exports.COLOR_DATA_NODE = '#6DDFDB';
exports.COLOR_BACKGROUND = '#001C2E';
