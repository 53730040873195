"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useInterval({ callback, delay }) {
    const savedCallback = (0, react_1.useRef)(() => { });
    (0, react_1.useEffect)(() => {
        savedCallback.current = callback;
    });
    (0, react_1.useEffect)(() => {
        const tick = () => {
            savedCallback.current();
        };
        const timer = setInterval(tick, delay);
        return () => clearInterval(timer);
    }, [delay]);
}
exports.default = useInterval;
