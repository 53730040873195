"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const react_2 = require("react");
function MovingDot({ from, to, velocity, color, radius = 25 }) {
    const [position, setPosition] = (0, react_2.useState)({
        x: from[0],
        y: from[1],
    });
    const [opacity, setOpacity] = (0, react_2.useState)(0);
    const distance = Math.sqrt((to[0] - from[0]) ** 2 + (to[1] - from[1]) ** 2);
    const time = Math.floor(distance / velocity);
    (0, react_2.useEffect)(() => {
        setOpacity(1);
        const tick1 = () => {
            setOpacity(0);
        };
        const tick2 = () => {
            setPosition({
                x: to[0],
                y: to[1],
            });
        };
        const timer1 = setTimeout(tick1, time * 1000);
        const timer2 = setTimeout(tick2, 100);
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);
    return position ? ((0, jsx_runtime_1.jsx)("circle", { cx: position.x, cy: position.y, r: radius, css: (0, react_1.css) `
        filter: url('#glow');
        transition: cx ${time}s ease-in-out, cy ${time}s ease-in-out,
          opacity 0.2s ease-in-out;
        fill: ${color};
        opacity: ${opacity};
      ` })) : null;
}
exports.default = MovingDot;
