"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomInteger = exports.getRandom = void 0;
function getRandom(min, max) {
    return (max - min) * Math.random() + min;
}
exports.getRandom = getRandom;
function getRandomInteger(min, max) {
    return Math.floor(getRandom(min, max));
}
exports.getRandomInteger = getRandomInteger;
