"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const color_1 = require("../styles/color");
function Glow({ x, y, radius = 30 }) {
    return ((0, jsx_runtime_1.jsx)("circle", { css: (0, react_1.css) `
        fill: ${color_1.staticDot};
        filter: url('#outer-glow');
      `, cx: (x + radius).toFixed(0), cy: (y + radius).toFixed(0), r: radius + 3 }));
}
exports.default = Glow;
