"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
function Scrim({ onClick }) {
    return ((0, jsx_runtime_1.jsx)("div", { onKeyDown: () => { }, onClick: onClick, css: (0, react_1.css) `
        width: 100vw;
        height: 100vh;

        position: fixed;
        top: 0;
        left: 0;
      ` }));
}
function Dialog({ children, isOpen, close }) {
    return isOpen ? ((0, jsx_runtime_1.jsxs)("div", Object.assign({ css: (0, react_1.css) `
        position: fixed;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;
      ` }, { children: [(0, jsx_runtime_1.jsx)(Scrim, { onClick: close }), (0, jsx_runtime_1.jsx)("div", Object.assign({ css: (0, react_1.css) `
          background-color: #fff;
          border-radius: 10px;
          padding: 10px 20px;

          z-index: 1;
        ` }, { children: children }))] }))) : null;
}
exports.default = Dialog;
