"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("react");
const react_2 = require("@emotion/react");
const spline_1 = require("../utils/spline");
const morph_1 = require("../utils/morph");
function MorphingDot({ posX = 0, posY = 0, noiseStep = 0.01, size = 40, }) {
    const ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        const points = (0, morph_1.createPoints)();
        let animationId;
        (function animate() {
            ref.current.setAttribute('d', (0, spline_1.spline)(points, 1, true));
            // for every point...
            for (let i = 0; i < points.length; i += 1) {
                const point = points[i];
                // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
                const nX = (0, morph_1.noise)(point.noiseOffsetX, point.noiseOffsetX);
                const nY = (0, morph_1.noise)(point.noiseOffsetY, point.noiseOffsetY);
                // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
                const x = (0, morph_1.map)(nX, -1, 1, point.originX - 20, point.originX + 20);
                const y = (0, morph_1.map)(nY, -1, 1, point.originY - 20, point.originY + 20);
                // update the point's current coordinates
                point.x = x;
                point.y = y;
                // progress the point's x, y values through "time"
                point.noiseOffsetX += noiseStep;
                point.noiseOffsetY += noiseStep;
            }
            animationId = requestAnimationFrame(animate);
        })();
        return () => {
            cancelAnimationFrame(animationId);
        };
    }, []);
    const opacityAnimation = (0, react_2.keyframes) `
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  `;
    return ((0, jsx_runtime_1.jsxs)("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: size, height: size, viewBox: "0 0 200 200", x: posX - size / 4, y: posY - size / 4, css: (0, react_2.css) `
        animation: ${opacityAnimation} 0.3s ease;
      ` }, { children: [(0, jsx_runtime_1.jsxs)("linearGradient", Object.assign({ id: "gradient", gradientTransform: "rotate(90)" }, { children: [(0, jsx_runtime_1.jsx)("stop", { id: "gradientStop1", offset: "0%", stopColor: "var(--startColor)" }), (0, jsx_runtime_1.jsx)("stop", { id: "gradientStop2 ", offset: "100%", stopColor: "var(--stopColor)" })] })), (0, jsx_runtime_1.jsx)("path", { ref: ref, d: "", fill: "url('#gradient')" }), ";"] })));
}
exports.default = MorphingDot;
