"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const store_1 = require("../store/store");
function GlobalStyle() {
    const { parameters } = (0, store_1.useParams)(state => ({
        parameters: state.parameters,
    }));
    return ((0, jsx_runtime_1.jsx)(react_1.Global, { styles: (0, react_1.css) `
        body {
          height: 100vh;
          width: 100vw;
          overflow: hidden;
          margin: 0;
          background-color: ${parameters.COLOR_BACKGROUND};
          font-family: sans-serif;
        }

        * {
          box-sizing: border-box;
        }
      ` }));
}
exports.default = GlobalStyle;
