"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("@emotion/react/jsx-runtime");
const react_1 = require("@emotion/react");
const defaultParameters = __importStar(require("../parameters"));
const store_1 = require("../store/store");
function ParameterPanel() {
    const { parameters, changeParams } = (0, store_1.useParams)(state => ({
        parameters: state.parameters,
        changeParams: state.changeParams,
    }));
    function handleNumberChange(e) {
        changeParams({
            param: e.target.id,
            amount: parseInt(e.target.value, 10),
        });
    }
    function handleColorChange(e) {
        changeParams({
            param: e.target.id,
            color: e.target.value,
        });
    }
    return ((0, jsx_runtime_1.jsx)("div", Object.assign({ css: (0, react_1.css) `
        display: flex;
        flex-direction: column;
        gap: 30px;

        min-width: 500px;
      ` }, { children: store_1.keys.map(key => ((0, jsx_runtime_1.jsxs)("label", Object.assign({ htmlFor: key, css: (0, react_1.css) `
            font-weight: 700;

            display: flex;
            justify-content: space-between;
            align-items: center;
          ` }, { children: [(0, jsx_runtime_1.jsx)("span", { children: key }), key.match(/^COLOR/) ? ((0, jsx_runtime_1.jsx)("input", { type: "color", id: key, value: parameters[key] || '', onChange: handleColorChange, css: (0, react_1.css) `
                outline: none;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #aaa;
                &:focus {
                  border: 1px solid #6ddfdb;
                }
              ` })) : ((0, jsx_runtime_1.jsx)("input", { type: "text", id: key, value: parameters[key] ||
                        defaultParameters[key], onChange: handleNumberChange, css: (0, react_1.css) `
                outline: none;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #aaa;
                &:focus {
                  border: 1px solid #6ddfdb;
                }
              ` }))] })))) })));
}
exports.default = ParameterPanel;
